import * as React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import PastEvents from "../components/events/past_commb_talks"



const PastTalks = () => (
  <div className="eventsPage talksPage">
    <Layout>
      <Seo title="Past COMMB Talks" />
      <div className="events_page">
        <div className="main_content_wrapper insight_page">
          <div className="spotlight_article_section">
            <div className="container">
              {/* <PastEvents /> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
)

export default PastTalks

